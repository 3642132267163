//游戏请求模块
import request from '../utils/request'
//获取游戏详情
export const gameInfo = (data) => {
    return request({
        method: 'post',
        url: '/game/info',
        data,
    })
}
//获取游戏登录地址
export const gamePlayUrl = (small_id) => {
    return request({
        method: 'post',
        url: '/game/play-url',
        data: {small_id},
    })
}
//游戏礼包列表
export function giftList(data){
    return request({
        method: 'post',
        url: '/game/giftbag-list',
        data,
    })
}
//游戏礼包详情
export function giftDetail(gift_id){
    return request({
        method: 'post',
        url: '/game/giftbag-detail',
        data:{gift_id},
    })
}
//领取礼包
export function receiveGift(gift_id,small_id){
    return request({
        method: 'post',
        url: '/game/receive-gift',
        data:{gift_id,small_id},
    })
}
//游戏开服表
export function serverList(data){
    return request({
        method: 'post',
        url: '/game/server-list',
        data,
    })
}
//游戏活动
export function activityList(data){
    return request({
        method: 'post',
        url: '/game/activity-list',
        data,
    })
}
//游戏活动详情
export function activityDetail(id){
    return request({
        method: 'post',
        url: '/game/activity-detail',
        data:{id},
    })
}

export function getPlayUrl(small_id,small_token){
    return request({
        method:'post',
        url:'/game/play-url',
        data:{small_id,small_token}
    })
}
