<template>
  <div class="main gray-bg">
    <van-nav-bar class="top-bar has-bg-bar" title="开服表" left-arrow @click-left="onClickLeft"/>
    <van-list class="gift-list" v-model:loading="loading"
              :finished="finished"
              finished-text="没有更多了"
              @load="getServerList">
      <van-cell v-for="(item, key) in list" :key="key" class="server-cell">
        <template #title>
          <div class="van-ellipsis server-title">{{ item.server_name }}</div>
        </template>
        <template #value>
          <van-tag v-if="getTime() < item.start_time" plain color="#4983FE">未开服</van-tag>
          <van-tag v-else plain color="#FFA40C">已开服</van-tag>
        </template>
        <template #right-icon>
          <div class="van-ellipsis server-time">{{ date(item.start_time, 'Y-m-d H:i:s') }}</div>
        </template>
      </van-cell>
    </van-list>
  </div>
</template>

<script>

import {NavBar,Cell, List,Tag} from "vant";
import {reactive, ref} from "vue";
import {serverList} from "@/api/game";
import {date} from "@/utils/function";
import {getTime} from "@/utils/make";
import {exitPage} from "@/utils/callback";

export default {
  name: "ServerList",
  components: {
    [NavBar.name]: NavBar,
    [Cell.name]: Cell,
    [List.name]: List,
    [Tag.name]: Tag,
  },
  setup() {
    const list = ref([])
    const query = reactive({
      last: 0,
      limit: 10
    })
    const loading = ref(false);
    const finished = ref(false);
    const onClickLeft = () => {
      exitPage()
    };
    const getServerList = () => {
      loading.value = true;
      let oldList = list.value;
      serverList(query).then(({data}) => {
        loading.value = false;
        list.value = [...oldList,...data.list];
        query.last = data.last
        if (data.list.length < query.limit || data.last == 0) {
          finished.value = true;
        }
      })
    }
    return {
      list,
      loading,
      finished,
      onClickLeft,
      getServerList,
      date,
      getTime,
    }
  }
}
</script>

<style scoped>
.top-bar :deep(.van-nav-bar__content) {
  background: url("../../assets/images/personal/server-bar.png") bottom center no-repeat;
  background-size: 100% auto;
}
.server-title{
  font-size: 14px;
  font-weight: 500;
  color: #1D2129;
  text-align: left;
  max-width:160px;
}
.server-cell :deep(.van-cell__value){
  text-align: left;
}
.server-time{
  font-size: 14px;
  font-weight: 500;
  color: #86909C;
}
</style>
